
@import url('https://fonts.googleapis.com/css2?family=Cairo:wght@400;700&family=Roboto:wght@400;700&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

body, html {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
}

nav {
  border: none; /* Remove any border */
  margin: 0; /* Ensure there's no margin around the nav */
  padding: 0; /* Ensure there's no padding around the nav */
  background-color: #004d4d; /* Match this color to your design */
}

.container {
  margin: 0; /* Remove container margins if any */
  padding: 0; /* Remove container padding if any */
  border: none; /* Remove container border if any */
}

.mx-auto {
  margin: 0 auto; /* Ensure the container is centered without extra margin */
}



body {
  font-family: 'Poppins', sans-serif;
}
.font-en {
  font-family: 'Roboto', sans-serif;
}

.font-ar {
  font-family: 'Cairo', sans-serif;
}

.background_footer {
  background: rgb(16, 68, 72);
  background: linear-gradient(90deg, rgba(16, 68, 72, 1) 0%, rgba(24, 145, 124, 1) 100%);
  transition: background 0.5s ease-in-out;
}

/* .background_footer:hover {
  background: rgb(16,68,72);
  background: linear-gradient(335deg, rgba(16,68,72,1) 9%, rgba(24,145,125,0.8604691876750701) 83%);
} */
.rtl {
  direction: rtl;
}
.ltr {
  direction: ltr;
}

@keyframes fadeInOutBorder {
  0%, 100% {
    opacity: 0;
    border-color: white;
  }
  50% {
    opacity: 1;
    border-color: yellow;
  }
}
.whatsapp-float {
  position: fixed;
  bottom: 10%;
  right: 0;
  transform: translateY(50%);
  background-color: #25d366;
  padding: 15px 10px;
  border-radius: 20px 0 0 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  z-index: 1000;
  transition: all 0.3s ease;
}

.whatsapp-float:hover {
  background-color: #20b858;
  transform: translateY(50%) scale(1.1);
}

.whatsapp-float a {
  color: white;
  text-align: center;
  display: block;
}

.whatsapp-float a:hover {
  color: white;
}



@keyframes rotateGradient {
  0% {
    background-position: 0% 50%;
  }
  100% {
    background-position: 200% 50%;
  }
}

.animated-border {
  border: 4px solid transparent;
  border-radius: 16px;
  background-origin: border-box;
  background-clip: content-box, border-box;
  background-image: linear-gradient(white, white), linear-gradient(45deg, #104448, #ffcf32, #18917c, #4CAF50);
  background-size: 200% 200%; /* Adjust the size for more noticeable animation */
  animation: rotateGradient 3s linear infinite;
}
li strong {
  color: #ffcf32; /* Change this color to your preferred one */
}
/* Add this in your CSS file */
.styled-header {
  font-weight: bold;
  color: #ff5733; /* Customize the color */
  margin-bottom: 1rem; /* Space after each header */
  border-bottom: 2px solid #ff5733; /* Optional: Adding an underline */
  padding-bottom: 0.5rem; /* Space between text and underline */
}


/* Add styles to adjust the size or color of icons */
.react-icon {
  font-size: 1.5rem;
  color: #ff5733; /* Example color */
}
